import React, { useEffect, useState } from "react";

import { Field, Form, Formik } from "formik";
import * as Yup from "yup";

import {
    AttachFile,
    Cancel
} from "@mui/icons-material";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

import GPTModalButton from "../GPTModalButton";
import InputVariables from "../InputVariables";

import { toast } from "react-toastify";
import appVars from "../../../package.json";
import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";
import ConnectionItems from "../ConnectionItems";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useTenant from "../../hooks/useTenant";
import useWhatsApps from "../../hooks/useWhatsApps";
import MultiFieldLine from "../FormComponents/MultiFieldLine";

const useStyles = makeStyles(theme => ({
    inputNumber: {
        width: 200
    },
    inputConnection: {
        margin: "dense",
        marginTop: 8,
        width: "24ch"
    },
    inputDateTime: {
        width: 460
    },
    newMessageBox: {
        marginTop: theme.spacing(1),
        width: "100%",
        display: "flex",
        padding: "7px",
        alignItems: "left",
        border: "1px solid",
        borderRadius: 15
    },
    uploadInput: {
        display: "none",
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1,
    },
    buttonColorError: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
    },
    buttonProgress: {
        color: theme.palette.secondary.main,
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
}));

const ScheduleSchema = Yup.object().shape({
    message: Yup.string()
        .min(3, i18n.t("translation.validation.too_short"))
        .max(400, i18n.t("translation.validation.too_long")),
    phoneNumber: Yup.string()
        .min(12, i18n.t("translation.validation.too_short"))
        .max(15, i18n.t("translation.validation.too_long")),
    sendAt: Yup.date()
        .min(new Date(), i18n.t("translation.validation.dateNow"))
})

const ScheduleMessageModal = ({ user, open, onClose, contactId, messageId, ticket }) => {
    const classes = useStyles();

    const { whatsApps } = useWhatsApps();
    const { tenantId, userTenant } = useTenant();

    const initialState = {
        id: messageId || '',
        contactId: contactId || ticket?.contact.id || '',
        phoneNumber: ticket?.contact.number || '',
        message: `${i18n.t("translation.variables.campaign.content")} {{firstName}}, `,
        hasMedia: false,
        wpId: ticket?.whatsappId || '',
        userTenantId: userTenant?.id,
        status: 'pending',
        ticketId: ticket?.id || 0,
        sendAt: '',
    };

    const [loadContactField, setLoadContactField] = useState(false);
    const [inputs, setInputs] = useState(initialState);
    const [medias, setMedias] = useState([]);
    const [title, setTitle] = useState(i18n.t("translation.scheduledMessagesModal.form.title"));
    const [field, setField] = useState();

    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const fetchContact = async () => {
            if (!contactId) {
                setInputs(inputs => ({ ...inputs, message: `${i18n.t("translation.variables.campaign.content")}` }));
                return;
            }

            if (!tenantId) return;
            try {
                const { data } = await axiosPrivate.get(`/${tenantId}/contacts/${contactId}`);
                isMounted && setInputs(inputs => ({
                    ...inputs,
                    contactId: data.id,
                    phoneNumber: data.number,
                    message: `${i18n.t("translation.variables.campaign.content")} {{firstName}}, `,
                }));
                isMounted && setTitle(i18n.t("translation.scheduledMessagesModal.form.titleContact", { contact: data.name }))
            } catch (err) {
                toastError(err);
            }
        };

        fetchContact();

        return () => {
            isMounted = false;
            controller.abort();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contactId, open, tenantId])

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        if (!ticket) {
            return;
        } else {
            isMounted && setInputs(inputs => ({
                ...inputs,
                contactId: ticket.contact.id,
                phoneNumber: ticket.contact.number,
                ticketId: ticket.id,
                wpId: ticket.whatsappId,
            }));
        }

        return () => {
            isMounted = false;
            controller.abort();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ticket]);

    //componentize
    const getFileName = (mediaPath) => {
        let breakPath = mediaPath.split("/");
        let pathCount = breakPath.length - 1;
        let fileName = breakPath[pathCount]
        return fileName
    }

    const handleImageAsAttachment = (mediaPath) => {
        fetch(mediaPath).then(async response => {
            const contentType = response.headers.get('content-type')
            const blob = await response.blob()
            const file = new File([blob], getFileName(mediaPath), { contentType })
            let arrayMedias = Array.from([file])
            setMedias(arrayMedias)
            return
        })
    }

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const fetchScheduledMessages = async () => {
            if (!messageId || !tenantId) return;
            try {
                const { data } = await axiosPrivate.get(`/${tenantId}/scheduledMessages/${messageId}`, { signal: controller.signal });
                isMounted && setInputs(inputs => ({
                    ...inputs,
                    id: data.id,
                    contactId: data.contactId,
                    phoneNumber: data.phoneNumber,
                    message: data.message,
                    hasMedia: data.mediaUrl?.length > 0 ? true : false,
                    wpId: data.wpId,
                    userTenantId: data.userTenantId,
                    status: data.status,
                    ticketId: data.ticketId,
                    sendAt: data.sendAt.substring(0, 16)
                }));
                if (data.mediaUrl) {
                    handleImageAsAttachment(data.mediaUrl)
                };
                setTitle(i18n.t("translation.scheduledMessagesModal.form.titleContact", { contact: data.name }))
            } catch (err) {
                toastError(err);
            }
        };

        fetchScheduledMessages();

        return () => {
            isMounted = false;
            controller.abort();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messageId, tenantId]);

    useEffect(() => {
        // let contact = (ticket?.id || contactId ) ? false : true;
        setLoadContactField(() => (contactId || messageId || ticket?.id) ? false : true)
    }, [contactId, messageId, ticket]);

    //Componentize
    const handleInputMedias = e => {
        if (!e.target.files) return;
        const selectedMedias = Array.from(e.target.files);
        setMedias(selectedMedias);
    };

    const handleClose = () => {
        onClose();
        setInputs(initialState);
        setInputs(inputs => ({ ...inputs, message: `${i18n.t("translation.variables.campaign.content")} {{firstName}}` }));
        setMedias([]);
    };

    const handleFocus = (e) => {
        setField(e.target);
    }

    // TODO: Componentize
    const InputFile = () => {
        if (medias?.length > 0)
            return <>
                <div className={classes.newMessageBox}>
                    <Button
                        startIcon={<Cancel color="error" />}
                        component="span"
                        onClick={e => setMedias([])}
                        size="small"
                    >
                        <Typography>
                            {medias[0]?.name}
                            {medias.length > 1 ? i18n.t("translation.validation.medias.hasOthers", { medias: medias.length }) : ''}
                        </Typography>
                    </Button>
                </div>
            </>;
        else
            return <>
                <div className={classes.newMessageBox}>

                    <input
                        multiple
                        type="file"
                        id="upload-button2"
                        className={classes.uploadInput}
                        onChange={handleInputMedias}
                    />
                    <label htmlFor="upload-button2">
                        <Button
                            startIcon={<AttachFile />}
                            component="span"
                            size="small"
                            variant="outlined"
                        >
                            <Typography>{i18n.t("translation.validation.medias.none")}</Typography>
                        </Button>
                    </label>
                </div>
            </>
    };

    const checkContent = (inputs) => {
        if (inputs.phoneNumber === "") {
            toast.error(i18n.t("translation.scheduledMessagesModal.toasts.missingNumber"))
            return
        }
        if (inputs.wpId === "") {
            toast.error(i18n.t("translation.scheduledMessagesModal.toasts.missingConnection"))
            return
        }
        if (!inputs.sendAt) {
            toast.error(i18n.t("translation.scheduledMessagesModal.toasts.missingSchedule"))
            return
        }
        if (inputs.message === "") {
            toast.error(i18n.t("translation.scheduledMessagesModal.toasts.missingMessage"))
            return
        }
        medias ? handleSendMedia(inputs, medias) : handleSendMessage(inputs);
    }

    const handleSaveSchedule = (values) => {
        let scheduledMessageData = {
            ...inputs,
            phoneNumber: values.phoneNumber ? values.phoneNumber : inputs.phoneNumber,
            message: values.message,
            wpId: values.wpId,
            sendAt: values.sendAt,
            status: "pending"
        };

        setInputs(inputs => ({
            ...inputs,
            phoneNumber: values.phoneNumber ? values.phoneNumber : inputs.phoneNumber,
            message: values.message,
            wpId: values.wpId,
            sendAt: values.sendAt
        }));

        checkContent(scheduledMessageData)
    }

    const handleSendMedia = (inputs, medias) => {
        handleSendMessage(inputs, medias);
    }

    const handleSendMessage = async (inputs, medias) => {
        try {
            let values
            if (medias.length > 0) {
                values = new FormData();
                values.append("contactId", inputs.contactId);
                values.append("phoneNumber", inputs.phoneNumber);
                values.append("message", inputs.message);
                values.append("wpId", inputs.wpId);
                values.append("userTenantId", inputs.userTenantId);
                values.append("status", inputs.status);
                values.append("ticketId", inputs.ticketId);
                values.append("sendAt", inputs.sendAt);
                medias.forEach((media) => {
                    values.append("file", media);
                });
            } else {
                values = {
                    contactId: inputs.contactId,
                    phoneNumber: inputs.phoneNumber,
                    message: inputs.message,
                    mediaUrl: null,
                    wpId: inputs.wpId,
                    userTenantId: inputs.userTenantId,
                    status: inputs.status,
                    ticketId: inputs.ticketId,
                    sendAt: inputs.sendAt,
                };
            }

            if (tenantId) {
                if (messageId) {
                    await axiosPrivate.put(`/${tenantId}/scheduledMessages/${messageId}`, values);
                } else {
                    await axiosPrivate.post(`/${tenantId}/scheduledMessages`, values);
                }
            }
            handleClose();
            toast.success(i18n.t("translation.scheduledMessagesModal.toasts.success"));

        } catch (err) {
            toastError(err);
        }
    }

    return (
        <Dialog open={open} onClose={handleClose} scroll="paper">
            <DialogTitle id="form-dialog-title">
                {title}
            </DialogTitle>
            <Formik
                initialValues={inputs}
                enableReinitialize={true}
                validationSchema={ScheduleSchema}
                onSubmit={(values, actions, e) => {
                    setTimeout(() => {
                        handleSaveSchedule(values);
                        actions.setSubmitting(false)
                    }, 400);
                }}
            >
                {({ touched, errors, isSubmitting, values, setFieldValue }) => (
                    <Form>
                        <DialogContent dividers>
                            <MultiFieldLine>
                                {loadContactField ?
                                    <>
                                        <Field
                                            as={TextField}
                                            label={i18n.t("translation.scheduledMessagesModal.form.number")}
                                            id="phoneNumber"
                                            name="phoneNumber"
                                            variant="outlined"
                                            size="small"
                                            className={classes.textField}
                                            error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                                            helperText={touched.phoneNumber && errors.phoneNumber}
                                            placeholder={appVars.examples.phone}
                                        // onChange={e => { handleChange(e) }}
                                        />
                                    </> : ''}
                                <Field
                                    as={TextField}
                                    label={i18n.t("translation.scheduledMessagesModal.form.date")}
                                    type="datetime-local"
                                    ampm="false"
                                    InputLabelProps={{ shrink: true, }}
                                    inputProps={{ step: 600 }} //5 minutes
                                    name="sendAt"
                                    error={touched.sendAt && Boolean(errors.sendAt)}
                                    helperText={touched.sendAt && errors.sendAt}
                                    variant="outlined"
                                    size="small"
                                    // onChange={e => { handleChange(e) }}
                                    fullWidth
                                    className={classes.textField}
                                />
                            </MultiFieldLine>

                            <FormControl variant="outlined" size="small" className={classes.maxWidth} fullWidth>
                                <InputLabel>{i18n.t("translation.campaigns.form.id")}</InputLabel>
                                <Field
                                    as={Select}
                                    label={i18n.t("translation.campaigns.form.id")}
                                    name="wpId"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    placeholder={i18n.t("translation.campaigns.form.id")}
                                // onChange={e => { handleChange(e) }}
                                >
                                    {ConnectionItems(whatsApps)}
                                </Field>
                            </FormControl>

                            <GPTModalButton
                                disabled={isSubmitting}
                                field={field}
                                setFieldValue={setFieldValue}
                                prompt={i18n.t("translation.gptModal.prompts.scheduledMessage")}
                            />
                            <Field
                                as={TextField}
                                label={i18n.t("translation.campaigns.form.message")}
                                id="message"
                                name="message"
                                autoFocus
                                onFocus={handleFocus}
                                multiline
                                minRows={3}
                                fullWidth
                                error={touched.message && Boolean(errors.message)}
                                helperText={touched.message && errors.message}
                                variant="outlined"
                                size="small"
                            // onChange={e => {handleChange(e)}}
                            />

                            <InputVariables group={contactId ? "scheduledMessages" : "scheduledMessagesNoContact"} field={field} setFieldValue={setFieldValue} />
                            <InputFile />


                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={handleClose}
                                className={classes.buttonColorError}
                                disabled={isSubmitting}
                                variant="outlined"
                            >
                                {i18n.t("translation.validation.buttons.cancel")}
                            </Button>
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                disabled={isSubmitting}
                                className={classes.btnWrapper}
                            >
                                {contactId
                                    ? `${i18n.t("translation.validation.buttons.okEdit")}`
                                    : `${i18n.t("translation.validation.buttons.schedule")}`}
                                {isSubmitting && (
                                    <CircularProgress
                                        size={24}
                                        className={classes.buttonProgress}
                                    />
                                )}
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog >
    );
};

export default ScheduleMessageModal;
