import { format, parseISO } from "date-fns";
import React, { useContext, useState } from "react";

import { DoneAll } from "@mui/icons-material";
import {
    Divider,
    Menu,
    MenuItem,
    Typography
} from "@mui/material";

import { ReplyMessageContext } from "../../context/ReplyingMessage/ReplyingMessageContext";
import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";
import ConfirmationModal from "../ConfirmationModal";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useTenant from "../../hooks/useTenant";

const MessageOptionsMenu = ({ message, menuOpen, handleClose, anchorEl }) => {
    const { setReplyingMessage } = useContext(ReplyMessageContext);
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const { tenantId } = useTenant();

    const axiosPrivate = useAxiosPrivate();

    const handleDeleteMessage = async () => {
        if (tenantId) {
            try {
                await axiosPrivate.delete(`/${tenantId}/messages/${message.id}`);
            } catch (err) {
                toastError(err);
            }
        }
    };

    const handleTranscribeMessage = async () => {
        if (tenantId) {
            try {
                await axiosPrivate.post(`/${tenantId}/messages/transcribe/${message.id}`);
            } catch (err) {
                toastError(err);
            }
        }
    };

    const hanldeReplyMessage = () => {
        setReplyingMessage(message);
        handleClose();
    };

    const handleOpenConfirmationModal = (e) => {
        setConfirmationOpen(true);
        handleClose();
    };

    return (
        <>
            <ConfirmationModal
                title={i18n.t("translation.messageOptionsMenu.confirmationModal.title")}
                open={confirmationOpen}
                onClose={setConfirmationOpen}
                onConfirm={handleDeleteMessage}
            >
                {i18n.t("translation.messageOptionsMenu.confirmationModal.message")}
            </ConfirmationModal>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={menuOpen}
                onClose={handleClose}
            >
                {!message.isComment &&
                    <MenuItem onClick={hanldeReplyMessage}>
                        {i18n.t("translation.messageOptionsMenu.reply")}
                    </MenuItem>
                }
                {message.mediaType === "audio" && message.transcription === null && (
                    <MenuItem onClick={handleTranscribeMessage}>
                        {i18n.t("translation.messageOptionsMenu.trascribe")}
                    </MenuItem>
                )}
                {message.fromMe && (
                    <MenuItem onClick={handleOpenConfirmationModal}>
                        <Typography color="error"> {i18n.t("translation.messageOptionsMenu.delete")} </Typography>
                    </MenuItem>
                )}
                {(message.senderId || message.readAt || message.deliveredAt) && message.fromMe && (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Divider />
                        {message.senderId && (
                            <Typography variant="caption" sx={{ display: "flex", alignItems: "center", padding: "8px 4px 0", opacity: "50%", alignSelf: "center" }}>
                                {`\u200e` === message.body.charAt(0) && `\ufeff` === message.body.charAt(0)
                                    ? i18n.t("translation.messageOptionsMenu.sender.system")
                                    : i18n.t("translation.messageOptionsMenu.sender.user", { user: message?.sender?.user?.name })}
                            </Typography>
                        )}
                        {(message.readAt || message.deliveredAt) && (
                            <Typography variant="caption" sx={{ display: "flex", alignItems: "center", padding: "0 4px", opacity: "50%" }}>
                                <DoneAll fontSize="small" sx={{ marginRight: "4px" }} /> {message.readAt
                                    ? i18n.t("translation.contactDrawer.ack.read") + ": " + format(parseISO(message.readAt), "dd/MM/yyyy HH:mm")
                                    : message.deliveredAt
                                        ? i18n.t("translation.contactDrawer.ack.delivered") + ": " + format(parseISO(message.deliveredAt), "dd/MM/yyyy HH:mm")
                                        : ""}
                            </Typography>
                        )}
                    </div>
                )}
            </Menu>
        </>
    );
};

export default MessageOptionsMenu;
